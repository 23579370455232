<template>
  <main class="feedbackForm">
    <div class="container">
      <div class="feedbackForm__wrapper">
        <div class="feedbackForm__card" v-if="currentView == 'accepted'">
          <h1 class="feedbackForm__mainHeading">
            <span
              class="tw-ps-2 tw-text-xl tw-font-semibold md:tw-text-4xl md:tw-font-bold"
              >{{ $t('feedbackForm.areYouSureToAccept') }}</span
            >
          </h1>
          <div
            v-if="talent"
            class="tw-flex tw-justify-center tw-flex-col tw-items-center tw-my-8"
          >
            <div class="tw-flex tw-justify-center tw-flex-col tw-items-center tw-bg-[#FAFAFA] tw-p-6 tw-pb-0 tw-border tw-border-[#f1f1f1] tw-border-solid md:tw-border-none md:tw-bg-white tw-w-full tw-rounded-xl tw-mb-6  ">
              <figure>
                <img
                  class="tw-w-40 tw-h-40 tw-rounded-full"
                  :src="talentProfilePic"
                  @error="imageLoadOnError"
                  alt="Talent image"
                />
              </figure>
              <h2 class="tw-flex tw-flex-col tw-mb-8">
                <span
                  class="tw-text-center tw-text-[#292929] tw-text-[22px] tw-font-bold tw-leading-[41.75px] tw-mb-4"
                >
                  {{ talent.full_name }}
                </span>
                <span
                  class="tw-text-center tw-text-[#422E87] tw-text-base tw-font-semibold tw-leading-relaxed"
                >
                  {{ talent.role.name }}
                </span>
              </h2>
            </div>

            <div class="tw-flex tw-gap-4 tw-w-full">
              <button
                @click="currentView = 'feedback'"
                class="tw-flex tw-justify-center tw-py-2 tw-px-8 tw-w-1/2 tw-text-center tw-text-[#FFC628] tw-border-[#FFC628] tw-border-2 tw-border-solid tw-text-lg tw-font-semibold tw-leading-[29.52px] tw-rounded-xl"
              >
                {{ $t('feedbackForm.cancel') }}
              </button>
              <button
                @click="acceptOrRejectInterview('accepted')"
                class="tw-flex tw-justify-center tw-bg-[#FFC628] tw-w-1/2 tw-py-2 tw-px-8 tw-text-center tw-text-black tw-text-lg tw-font-semibold tw-leading-[29.52px] tw-rounded-xl"
              >
                {{ $t('feedbackForm.confirm') }}
              </button>
            </div>
          </div>
          <div
            v-else
            class="tw-flex tw-justify-center tw-flex-col tw-items-center"
          >
            <figure class="tw-flex">
              <img
                class="tw-w-40 tw-h-40 tw-rounded-full"
                :src="talentProfilePic"
                alt="Talent image"
              />
            </figure>
            <h2>
              <span class=""> Unkown Talent </span>
            </h2>
          </div>
        </div>
        <div class="feedbackForm__card" v-if="currentView == 'rejected'">
          <h1 class="feedbackForm__mainHeading">
            <span
              class="tw-ps-2 tw-text-xl tw-font-semibold md:tw-text-4xl md:tw-font-bold"
              >{{ $t('feedbackForm.areYouSureToReject') }}</span
            >
          </h1>
          <div
            v-if="talent"
            class="tw-flex tw-justify-center tw-flex-col tw-items-center tw-my-8"
          >
            <div class="tw-flex tw-justify-center tw-flex-col tw-items-center tw-bg-[#FAFAFA] tw-p-6 tw-pb-0 tw-border tw-border-[#f1f1f1] tw-border-solid md:tw-border-none md:tw-bg-white tw-w-full tw-rounded-xl tw-mb-6  ">
              <figure>
                <img
                  class="tw-w-40 tw-h-40 tw-rounded-full"
                  :src="talentProfilePic"
                  @error="imageLoadOnError"
                  alt="Talent image"
                />
              </figure>
              <h2 class="tw-flex tw-flex-col tw-mb-8">
                <span
                  class="tw-text-center tw-text-[#292929] tw-text-[22px] tw-font-bold tw-leading-[41.75px] tw-mb-4"
                >
                  {{ talent.full_name }}
                </span>
                <span
                  class="tw-text-center tw-text-[#422E87] tw-text-base tw-font-semibold tw-leading-relaxed"
                >
                  {{ talent.role.name }}
                </span>
              </h2>
            </div>

            <div class="tw-mb-8 tw-w-full tw-text-start">
              <Textarea
                :validationField="$v.reason"
                :placeholder="$t('feedbackForm.WriteReason')"
                rows="8"
                :params="[{ maxLength: { number: '1000' } }, 'required']"
              />
            </div>

            <div class="tw-flex tw-gap-4 tw-w-full">
              <button
                @click="currentView = 'feedback'"
                class="tw-flex tw-justify-center tw-py-2 tw-px-8 tw-w-1/2 tw-text-center tw-text-[#FFC628] tw-border-[#FFC628] tw-border-2 tw-border-solid tw-text-lg tw-font-semibold tw-leading-[29.52px] tw-rounded-xl"
              >
                {{ $t('feedbackForm.cancel') }}
              </button>
              <button
                @click="acceptOrRejectInterview('rejected')"
                class="tw-flex tw-justify-center tw-bg-[#FFC628] tw-w-1/2 tw-py-2 tw-px-8 tw-text-center tw-text-black tw-text-lg tw-font-semibold tw-leading-[29.52px] tw-rounded-xl"
              >
                {{ $t('feedbackForm.confirm') }}
              </button>
            </div>
          </div>
          <div
            v-else
            class="tw-flex tw-justify-center tw-flex-col tw-items-center"
          >
            <figure class="tw-flex">
              <img
                class="tw-w-40 tw-h-40 tw-rounded-full"
                :src="talentProfilePic"
                alt="Talent image"
              />
            </figure>
            <h2>
              <span class=""> Unkown Talent </span>
            </h2>
          </div>
        </div>
        <div class="feedbackForm__card" v-if="currentView == 'feedback'">
          <h1 class="feedbackForm__mainHeading">
            <span
              class="tw-ps-2 tw-text-xl tw-font-semibold md:tw-text-4xl md:tw-font-bold"
              >{{ $t("feedbackForm.mainHeading") }}</span
            >
          </h1>
          <h3 class="feedbackForm__subHeading">
            <span class="tw-ps-2">{{ $t("feedbackForm.subHeading") }}</span>
          </h3>

          <div v-if="talent" class="feedbackForm__talent">
            <figure>
              <img
                :src="talentProfilePic"
                @error="imageLoadOnError"
                alt="Talent image"
              />
            </figure>
            <h2>
              <span class="feedbackForm__talentName">
                {{ talent.full_name }}
              </span>
              <small>•</small>
              <span class="feedbackForm__talentRole">
                {{ talent.role.name }}
              </span>
            </h2>
          </div>
          <div v-else class="feedbackForm__talent">
            <figure>
              <img :src="talentProfilePic" alt="Talent image" />
            </figure>
            <h2>
              <span class="feedbackForm__talentName"> Unkown Talent </span>
              <small>•</small>
            </h2>
          </div>

          <div class="feedbackForm__actions">
            <button @click="currentView = 'accepted'">
              <div v-if="accepting" class="pagination-loader"></div>
              <img v-else src="@/assets/images/ic_accept.svg" alt="Renew" />
              <span>{{ $t("feedbackForm.accept") }}</span>
            </button>
            <button @click="currentView = 'rejected'">
              <div v-if="rejecting" class="pagination-loader reject"></div>
              <img
                v-else
                src="@/assets/images/contract-renewal/reject-white.svg"
                alt="Reject"
              />
              <span>{{ $t("feedbackForm.reject") }}</span>
            </button>
          </div>
        </div>
        <div v-if="isLoading" class="feedbackForm__card">
          <div
            class="tw-flex tw-justify-center tw-flex-col tw-items-center tw-my-8"
          >
            <div
              class="tw-w-40 tw-h-8 tw-mt-2 tw-bg-gray-200 tw-rounded tw-mb-8"
            ></div>

            <div
              class="tw-w-40 tw-h-40 tw-mt-4 tw-bg-gray-200 tw-rounded-full"
            ></div>
            <div
              class="tw-w-40 tw-h-8 tw-mt-2 tw-bg-gray-200 tw-rounded tw-mb-4"
            ></div>
            <div
              class="tw-w-40 tw-h-8 tw-mt-2 tw-bg-gray-200 tw-rounded tw-mb-4"
            ></div>
            <div class="tw-flex tw-gap-4 tw-mt-4">
              <div class="tw-w-40 tw-h-12 tw-bg-gray-200 tw-rounded"></div>
              <div class="tw-w-40 tw-h-12 tw-bg-gray-200 tw-rounded"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import toast from "../../../services/toast";
import Textarea from "@/components/shared/formControls/textarea/textarea.vue";
import { maxLength, required } from "vuelidate/lib/validators";

export default {
  components: {
    Textarea,
  },
  data() {
    return {
      selection: null,
      talent: null,
      accepting: false,
      rejecting: false,
      currentView: null,
      isLoading: true,
      reason: "",
    };
  },
  computed: {
    talentProfilePic() {
      if (this.talent && this.talent.image) return `${this.talent.image}`;
      else return require("@/assets/images/talent-avatar.png");
    },
  },
  validations: {
    reason: {
      maxLength: maxLength(1000),
      required,
    },
  },
  async mounted() {
    const { selection } = this.$route.query;
    this.selection = selection;
    await this.getTalentData();
    // await this.acceptOrRejectInterview(action);
  },
  methods: {
    imageLoadOnError(e) {
      e.target.src = require("@/assets/images/talent-avatar.png");
    },
    async getTalentData() {
      await this.axios
        .get(`/api/talent-selection/${this.selection}`)
        .then((response) => {
          this.talent = response.data.data.talent;
          if (response.data.data.status.id != "completed") {
            toast.success(this.$t(`feedbackForm.doneAlready`));
            this.$router.push({ name: "clientMatching" });
          }
        })
        .catch((error) => {
          toast.error(error.response.data?.message || error.message);
        })
        .finally(() => {
          const { action } = this.$route.query;
          this.isLoading = false;
          this.currentView = action;
        });
    },
    async acceptOrRejectInterview(status) {
      status == "accepted" ? (this.accepting = true) : (this.accepting = false);
      status == "rejected" ? (this.rejecting = true) : (this.rejecting = false);

      let payload = {
        status,
      };
      if (status == "rejected") {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        payload = {
          ...payload,
          rejection_reason: this.reason,
        };
      }
      console.log("payload", payload);

      await this.axios
        .patch(`/api/talent-selection/${this.selection}`, {
          ...payload,
        })
        .then(() => {
          toast.success(this.$t(`feedbackForm.feedback`));
          this.$router.push({ name: "clientMatching" });
        })
        .catch((error) => {
          toast.error(error.response.data?.message || error.message);
        })
        .finally(() => {
          this.accepting = false;
          this.rejecting = false;
          this.reason = "";
        });
    },
  },
};
</script>


<style lang="scss">
.feedbackForm {
  &__wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8rem 0;
    @media (max-width: 768px) {
      margin: 2rem 0;
    }
  }

  &__card {
    background-color: #fff;
    padding: 2rem 8rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 900px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 15px 30px 0 #00000008;
    display: flex;
    flex-direction: column;
    text-align: center;
    @media (max-width: 768px) {
      padding: 2rem;
    }
  }

  &__talent {
    width: 100%;
    max-width: 650px;
    margin: 20px auto;
    background-color: #fafafa;
    border: 1px solid #f1f1f1;
    border-radius: 12px;
    padding: 15px 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    figure {
      margin: 0;
      img {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        object-fit: cover;
        @media (max-width: 768px) {
          width: 110px;
          height: 110px;
        }
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
    }
  }

  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    small {
      font-size: 16px;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 7px;

      small {
        display: none;
      }
    }
  }

  &__talentName {
    font-size: 20px;
    color: #292929;
    margin-inline-start: 20px;
    margin-inline-end: 4px;
    @media (max-width: 768px) {
      margin-inline: 0;
    }
  }

  &__talentRole {
    font-size: 15px;
    color: #422e87;
    margin-inline-start: 4px;
    @media (max-width: 768px) {
      margin-inline: 0;
    }
  }

  &__mainHeading {
    font-size: 32px;
    color: #292929;
    margin-bottom: 15px;
  }

  &__subHeading {
    font-size: 20px;
    color: #808285;
  }

  &__actions {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
    max-width: 650px;
    margin: 10px auto;

    @media (max-width: 768px) {
      width: 100%;
      justify-content: space-between;
    }

    button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      border-radius: 10px;
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s;
      img {
        width: 20px;
        margin-inline-end: 10px;
      }
    }

    button:first-child {
      background-color: #26daa6;
    }
    button:last-child {
      background-color: #ff6f59;
    }
  }
}

.pagination-loader {
  border: 4px solid #26daa6;
  border-top: 4px solid #f3f3f3;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-inline-end: 10px;
  animation: spin 1s linear infinite;

  &.reject {
    border: 4px solid #ff6f59;
    border-top: 4px solid #f3f3f3;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>